import { clubConstants } from "../actions/constants/clubConstant";

export const clubReducer = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.getAllClubsRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case clubConstants.getAllClubsSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        clubs: action.payload,
      };
    case clubConstants.getAllClubsFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const clubRegister = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.clubRegisterRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case clubConstants.clubRegisterSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isRegistered: true,
        isCreated: true,
        clubData: action.payload,
      };
    case clubConstants.clubRegisterFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const clubCreate = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.clubCreateRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case clubConstants.clubCreateSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isCreated: true,
      };
    case clubConstants.clubCreateFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    default:
      return state;
  }
};

export const clubVerifyOTP = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.verifyOtpRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case clubConstants.verifyOtpSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        otp: action.payload,
      };
    case clubConstants.verifyOtpFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const resendOtp = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.resendOtpRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case clubConstants.resendOtpSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        resendOtp: action.payload,
      };
    case clubConstants.resendOtpFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const clubGetByFilter = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.clubGetByFilterRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case clubConstants.clubGetByFilterSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isListed: true,
        clubs: action.payload.clubList,
        clubCount: action.payload.clubCount
      };
    case clubConstants.clubGetByFilterFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getClub = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.getClubRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.getClubSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isGet: true,
        club: action.payload
      }
    case clubConstants.getClubFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload
      }
    default:
      return state;
  }
}

export const updateClub = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.updateClubRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.updateClubSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isUpdated: true
      }
    case clubConstants.updateClubFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true
      }
    default:
      return state;
  }
}

export const deleteClub = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.deleteClubRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.deleteClubSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isDeleted: true
      }
    case clubConstants.deleteClubFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true
      }
    default:
      return state;
  }
}

export const getClubByDomain = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.getClubByDomainRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.getClubByDomainSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        domainResult: action.payload
      }
    case clubConstants.getClubByDomainFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true
      }
    default:
      return state;
  }
}

export const joinMember = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.memberJoiningRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.memberJoiningSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isJoined: true,
      }
    case clubConstants.memberJoiningFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload
      }
    default:
      return state;
  }
}

export const emailResend = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.memberEmailResendRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.memberEmailResendSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isResendEmail: true,
        resendEmail: action.payload
      }
    case clubConstants.memberEmailResendFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload
      }
    default:
      return state;
  }
}

export const resendWhatsApp = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.memberWhatsAppResendRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.memberWhatsAppResendSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        resendWhatsApp: action.payload
      }
    case clubConstants.memberWhatsAppResendFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload
      }
    default:
      return state;
  }
}

export const verifyPhone = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.memberPhoneVerificationRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.memberPhoneVerificationSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        verifyPhone: action.payload
      }
    case clubConstants.memberPhoneVerificationFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload
      }
    default:
      return state;
  }
}

export const tags = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.getTagsRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.getTagsSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isListed: true,
        tags: action.payload
      }
    case clubConstants.getTagsFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
      }
    default:
      return state;
  }
}

export const memberApproveReject = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.approveRejectMemberRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.approveRejectMemberSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
      }
    case clubConstants.approveRejectMemberFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
      }
    default:
      return state;
  }
}

export const memberInvitation = (state = {}, action: any) => {
  switch (action.type) {
    case clubConstants.memberInvitationRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    case clubConstants.memberInvitationSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isEmail: action.payload
      }
    case clubConstants.memberInvitationFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
      }
    default:
      return state;
  }
}

export const clubReport = (state = {}, action: any) => {
    switch (action.type) {
        case clubConstants.addClubReportRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case clubConstants.addClubReportSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isListed: true,
                reports: action.payload
            }
        case clubConstants.addClubReportFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const clubReportList = (state = {}, action: any) => {
  switch (action.type) {
      case clubConstants.clubReportListRequest:
          return {
              isLoading: true,
              isSuccess: false,
              isError: false
          }
      case clubConstants.ClubReporListSuccess:
          return {
              isLoading: false,
              isSuccess: true,
              isError: false,
              isListed: true,
              reports: action.payload
          }
      case clubConstants.ClubReportListFailure:
          return {
              isLoading: false,
              isSuccess: false,
              isError: true,
          }
      default:
          return state;
  }
}