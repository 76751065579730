import { clubConstants } from "./constants/clubConstant";

const clubRegister = (request: any) => {
  return { type: clubConstants.clubRegisterRequest, input: request };
};

const clubCreate = (request: any) => {
  return { type: clubConstants.clubCreateRequest, input: request };
};

const clubVerifyOTP = (request: any) => {
  return { type: clubConstants.verifyOtpRequest, input: request };
};

const resendotpRequest = (request: any) => {
  return { type: clubConstants.resendOtpRequest, payload: request };
};

const getAllClubs = () => {
  return { type: clubConstants.getAllClubsRequest }
}

const clubGetByFilter = (request: any) => {
  return { type: clubConstants.clubGetByFilterRequest, payload: request }
}

const getClub = (request: any) => {
  return { type: clubConstants.getClubRequest, input: request };
};

const addClubReport = (request: any) => {
  return { type: clubConstants.addClubReportRequest, input: request };
};

const clubReportList = (request: any) => {
  return { type: clubConstants.clubReportListRequest, input: request };
};


const updateClub = (request: any) => {
  return { type: clubConstants.updateClubRequest, input: request };
};

const deleteClub = (request: any) => {
  return { type: clubConstants.deleteClubRequest, input: request };
};

const getClubDomain = (request: any) => {
  return { type: clubConstants.getClubByDomainRequest, input: request };
};

const joinMember = (request: any) => {
  return { type: clubConstants.memberJoiningRequest, input: request };
};

const resendEmail = (request: any) => {
  return { type: clubConstants.memberEmailResendRequest, input: request };
};

const resendWhatsApp = (request: any) => {
  return { type: clubConstants.memberWhatsAppResendRequest, input: request };
};

const verifyPhone = (request: any) => {
  return { type: clubConstants.memberPhoneVerificationRequest, input: request };
};

const getTags = (request: any) => {
  return { type: clubConstants.getTagsRequest, input: request };
};

const memberApproveReject = (request: any) => {
  return { type: clubConstants.approveRejectMemberRequest, input: request };
};

const memberInvitation = (request: any) => {
  return { type: clubConstants.memberInvitationRequest, input: request };
};


export const clubActions = {
  clubRegister, resendotpRequest, clubVerifyOTP,
  clubCreate, getClub, updateClub, deleteClub,
  getAllClubs, clubGetByFilter,
  getClubDomain, getTags,addClubReport,clubReportList,
  joinMember, resendEmail, resendWhatsApp, verifyPhone, memberApproveReject,memberInvitation
};
