import axios from "axios"
import { axiosBase, errorHandler } from "./axios"
import env from "./env"

const getCarouselImages = (input: any) => {
    return axiosBase.post('/events/carouselevents', input)
        .then((response) => response.data)
        .catch(error => errorHandler(error))
}

const getPastEvents = (input: any) => {
    return axiosBase.post('/events/pastevents', input)
        .then((response) => response.data)
        .catch(error => errorHandler(error))
}

const getActiveEvents = (input: any) => {
    return axiosBase.post('/events/activeevents', input)
        .then((response) => response.data)
        .catch(error => errorHandler(error))
}

const getUpcomintEvents = (input: any) => {
    return axiosBase.post('events/upcomingevents', input)
        .then((response) => response.data)
        .catch(error => errorHandler(error))
}

const getImages = (input: any) => {
    return axiosBase.get('gallery/getimages/' + input)
        .then((response) => response.data)
        .catch(error => errorHandler(error))
}

const getUserLocation = (input: any) => {
    console.log('input..', input)
    return axiosBase.get(env.locationNominationApi, {
        params: {
            q: input,
            format: 'json',
            addressdetails: 1,
            limit: 5,
        }
    })
        .then((response) => response.data)
        .catch(error => errorHandler(error))
}

export const commonService = {
    getCarouselImages,
    getPastEvents,
    getActiveEvents,
    getUpcomintEvents,
    getImages,
    getUserLocation
}