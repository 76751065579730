import { call, put } from 'redux-saga/effects';
import { authService } from '../services/authService';
import { authConstants } from '../actions/constants/authConstant';
import { commonConstants } from '../actions/constants/commonConstant';

function* userLogin(request: any): any {
    try {
        const response = yield call(authService.userLogin, request.input);
        if (response.success) {
            yield put({ type: authConstants.userLoginSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: authConstants.userLoginFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: authConstants.userLoginFailure, payload: error });
    }
}

function* forgotPassword(request: any): any {
    try {
        const response = yield call(authService.forgotPassword, request.input);
        if (response.success) {
            yield put({ type: authConstants.forgotPasswordSuccess, payload: response.message });
        }
        else {
            yield put({
                type: authConstants.forgotPasswordFailure, payload: response.errorMessage
            });
        }
    } catch (error) {
        yield put({ type: authConstants.forgotPasswordFailure, payload: error });
    }
}

function* resendEmail(request: any): any {
    try {
        const response = yield call(authService.forgotPassword, request.input);
        if (response.success) {
            yield put({ type: authConstants.resendMailSuccess });
        }
        else {
            yield put({
                type: authConstants.resendMailFailure, payload: response.errorMessage
            });
        }
    } catch (error) {
        yield put({ type: authConstants.resendMailFailure, payload: error });
    }
}

function* forgotPasswordVerifyToken(request: any): any {
    try {
        const response = yield call(authService.forgotPasswordVerifyToken, request.input);
        if (response.success) {
            yield put({ type: authConstants.forgotPasswordVerifyTokenSuccess, payload: response.message });
        }
        else {
            yield put({
                type: authConstants.forgotPasswordVerifyTokenFailure, payload: response.errorMessage
            });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: authConstants.forgotPasswordVerifyTokenFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* renewToken(request: any): any {
    try {
        const response = yield call(authService.renewToken, request.input);
        if (response.success) {
            yield put({ type: authConstants.renewTokenSuccess, payload: response.resultObject });
            localStorage.setItem('AUTHDATA', JSON.stringify(response.resultObject));
        }
        else {
            yield put({
                type: authConstants.renewTokenFailure, payload: response.errorMessage
            });
        }
    } catch (error) {
        yield put({ type: authConstants.renewTokenFailure, payload: error });
    }
}

function* resetPassword(request: any): any {
    try {
        const response = yield call(authService.resetPassword, request.input);
        if (response.success) {
            yield put({ type: authConstants.resetPasswordSuccess, payload: response.message });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: authConstants.resetPasswordFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: authConstants.resetPasswordFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* updatePassword(request: any): any {
    try {
        const response = yield call(authService.updatePassword, request.input);
        if (response.success) {
            yield put({ type: authConstants.updatePasswordSuccess, payload: response.message });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
            yield put({ type: authConstants.updatePasswordSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: authConstants.updatePasswordFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: authConstants.updatePasswordFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

export const authSaga = {
    userLogin,
    forgotPassword,
    resendEmail,
    forgotPasswordVerifyToken,
    renewToken,
    resetPassword,
    updatePassword
}


