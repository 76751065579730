import { axiosBase, errorHandler } from "./axios";

const clubCreate = (input: any) => {
  return axiosBase.post("/clubs/create", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const clubVerifyOTP = (input: any) => {
  return axiosBase.post("/clubs/verifyotp", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const resendOtp = (input: any) => {
  return axiosBase.post("/clubs/resendotp", input)
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

const getAllClubs = () => {
  return axiosBase.get("/clubs/getallclubs")
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

const clubGetByFilter = (input: any) => {
  return axiosBase.post("/clubs/getbyfilter", input)
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

const getClub = (input: any) => {
  return axiosBase.get("/clubs/getclub/" + input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const updateClub = (input: any) => {
  return axiosBase.patch("/clubs/updateclub", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const deleteClub = (input: any) => {
  return axiosBase.delete("/clubs/deleteclub/" + input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const getClubByDomain = (input: any) => {
  return axiosBase.post("/clubs/getclubbydomain", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const joinMember = (input: any) => {
  return axiosBase.post("/members/addOnUser", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const resendEmail = (input: any) => {
  return axiosBase.post("/members/resendotp", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const getTags = (input: any) => {
  return axiosBase.get("/tags/getbyfilter/" + input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const memberApproveReject = (input: any) => {
  return axiosBase.post("/members/categorychange", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const memberInvitation = (input: any) => {
  return axiosBase.post("/members/invite", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const addClubReport = (input: any) => {
  return axiosBase.post("/clubs/report", input)
      .then((response) => response.data)
      .catch((error) => errorHandler(error));
};

const clubReportList = (input: any) => {
  return axiosBase.post("/clubs/reports", input)
      .then((response) => response.data)
      .catch((error) => errorHandler(error));
};


export const clubService = {
  clubCreate, clubVerifyOTP, resendOtp,
  getAllClubs, clubGetByFilter,
  getClub, updateClub, deleteClub, getClubByDomain,addClubReport,clubReportList,
  joinMember, resendEmail, getTags, memberApproveReject,memberInvitation
}