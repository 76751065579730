import { userConstants } from "../actions/constants/userConstant"
import { getAuthUser } from "../services/helper"

export const userReducer = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.getAllUserRequest:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.getAllUserSuccess:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                isError: false,
                users: action.payload
            }
        case userConstants.getAllUserFailure:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const clubManager = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.getClubManagersRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.getClubManagersSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isListed: true,
                managers: action.payload
            }
        case userConstants.getClubManagersFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const userCreate = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.createUserRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.createUserSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isCreated: true,
                createUser: action.payload
            }
        case userConstants.createUserFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const roles = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.getRolesRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.getRolesSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                Roles: action.payload
            }
        case userConstants.getRolesFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const userGetByFilter = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.getByFilterRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.getByFilterSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isListLoaded: true,
                isNotificationListed: true,
                userListData: action.payload
            }
        case userConstants.getByFilterFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }

        default:
            return state;
    }
}

export const getUser = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.getUserRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.getUserSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isGet: true,
                user: action.payload
            }
        case userConstants.getUserFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateUser = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.updateUserRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.updateUserSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isUpdated: true,
                userUpdate: action.payload
            }
        case userConstants.updateUserFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateProfile = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.updateProfileRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.updateProfileSuccess:
            let user = getAuthUser();
            let newData = {
                ...user, firstName: action.payload.firstName,
                lastName: action.payload.lastName,dateOfBirth:action.payload.dateOfBirth,
                contactNumber:action.payload.contactNumber,Address:action.payload.Address,
                city:action.payload.city,state:action.payload.state,zipcode:action.payload.zipcode
            }
            localStorage.setItem('AUTHDATA', JSON.stringify(newData));
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isUpdated: true,
                userUpdate: action.payload
            }
        case userConstants.updateProfileFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const deleteUser = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.deleteUserRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.deleteUserSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isDeleted: true,
                userDelete: action.payload
            }
        case userConstants.deleteUserFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const getProfile = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.getProfileRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.getProfileSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isProfileLoaded:true,
                userProfile: action.payload
            }
        case userConstants.getProfileFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const getUserNotification = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.getUserNotificationRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.getUserNotificationSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                notification: action.payload
            }
        case userConstants.getUserNotificationFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        default:
            return state;
    }
}

export const getNotificationCount = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.getNotificationCountRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.getNotificationCountSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                count: action.payload
            }
        case userConstants.getNotificationCountFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        default:
            return state;
    }
}

export const categoryChange = (state = {}, action: any) => {
    switch (action.type) {
        case userConstants.categoryChangeRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case userConstants.categoryChangeSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                category:action.payload,
                isCategoryChanged : true
            }
        case userConstants.categoryChangeFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        default:
            return state;
    }
    
}



