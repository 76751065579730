import { authConstants } from "../actions/constants/authConstant";

let user = localStorage.getItem('AUTHDATA');
const initialState = user ?
  { isLoggedIn: true, isLoading: false, authData: JSON.parse(user) } :
  { isLoggedIn: false, isLoading: true, authData: null };

export const authenticate = (state = initialState, action: any) => {
  switch (action.type) {
    case authConstants.userLoginRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isLoggeIn: false,
        isError: false,
      };
    case authConstants.userLoginSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isLoggedIn: true,
        isError: false,
        authData: action.payload,
      };
    case authConstants.renewTokenSuccess:
      return {
        ...state,
        authData: action.payload,
      }
    case authConstants.userLoginFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isLoggeIn: false,
        isError: true,
        response: action.payload,
      };
    default:
      return state;
  }
};

export const forgotPassword = (state = {}, action: any) => {
  switch (action.type) {
    case authConstants.forgotPasswordRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case authConstants.forgotPasswordSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        forgotData: action.payload,
      };
    case authConstants.forgotPasswordFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        response: action.payload,
      };
    default:
      return state;
  }
};

export const resendEmail = (state = {}, action: any) => {
  switch (action.type) {
    case authConstants.resendMailRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case authConstants.resendMailSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isResend: true,
      };
    case authConstants.resendMailFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        response: action.payload,
      };
    default:
      return state;
  }
};

export const verifyToken = (state = {}, action: any) => {
  switch (action.type) {
    case authConstants.forgotPasswordVerifyTokenRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case authConstants.forgotPasswordVerifyTokenSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        verifyToken: action.payload,
      };
    case authConstants.forgotPasswordVerifyTokenFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        TokenVerifyMessage: action.payload,
      };
    default:
      return state;
  }
};

export const renewToken = (state = {}, action: any) => {
  switch (action.type) {
    case authConstants.renewTokenRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case authConstants.renewTokenSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        isTokenRenewed: true,
        renewToken: action.payload,
      };
    case authConstants.renewTokenFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isTokenInvalid: true,
        isError: true,
        renewTokenMessage: action.payload,
      };
    default:
      return state;
  }
};

export const resetPassword = (state = {}, action: any) => {
  switch (action.type) {
    case authConstants.resetPasswordRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case authConstants.resetPasswordSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        resetPass: action.payload,
      };
    case authConstants.resetPasswordFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        response: action.payload,
      };
    default:
      return state;
  }
};

export const updatePassword = (state = {}, action: any) => {
  switch (action.type) {
    case authConstants.updatePasswordRequest:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case authConstants.updatePasswordSuccess:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        updatePass: action.payload,
      };
    case authConstants.updatePasswordFailure:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        response: action.payload,
      };
    default:
      return state;
  }
};

