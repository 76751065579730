import { call, put } from 'redux-saga/effects';
import { eventConstants } from '../actions/constants/eventConstant';
import { commonConstants } from '../actions/constants/commonConstant';
import { eventService } from '../services/eventService';

function* getAllEvents(request: any): any {
    try {
        const response = yield call(eventService.getAllEvents, request.input);
        if (response.success)
            yield put({ type: eventConstants.getAllEventsSuccess, payload: response.resultObject });
        else
            yield put({ type: eventConstants.getAllEventsFailure, payload: response.message });
    } catch (error) {
        yield put({ type: eventConstants.getAllEventsFailure, payload: error });
    }
}

function* getActiveEvents(request: any): any {
    try {
        const response = yield call(eventService.getAllEvents, request.input);
        if (response.success)
            yield put({ type: eventConstants.getActiveEventsSuccess, payload: response.resultObject });
        else
            yield put({ type: eventConstants.getActiveEventsFailure, payload: response.message });
    } catch (error) {
        yield put({ type: eventConstants.getActiveEventsFailure, payload: error });
    }
}

function* getUpComingEvents(request: any): any {
    try {
        const response = yield call(eventService.getAllEvents, request.input);
        if (response.success)
            yield put({ type: eventConstants.getUpComingEventsSuccess, payload: response.resultObject });
        else
            yield put({ type: eventConstants.getUpComingEventsFailure, payload: response.message });
    } catch (error) {
        yield put({ type: eventConstants.getUpComingEventsFailure, payload: error });
    }
}

function* getPastEvents(request: any): any {
    try {
        const response = yield call(eventService.getAllEvents, request.input);
        if (response.success)
            yield put({ type: eventConstants.getPastEventsSuccess, payload: response.resultObject });
        else
            yield put({ type: eventConstants.getPastEventsFailure, payload: response.message });
    } catch (error) {
        yield put({ type: eventConstants.getPastEventsFailure, payload: error });
    }
}

function* createEvent(request: any): any {
    try {
        const response = yield call(eventService.createEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.createEventSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.createEventFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.createEventFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getEvent(request: any): any {
    try {
        const response = yield call(eventService.getEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getEventSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getEventFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getEventFailure, payload: error });
    }
}

function* updateEvent(request: any): any {
    try {
        const response = yield call(eventService.updateEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.updateEventSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.updateEventFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.updateEventFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* deleteEvent(request: any): any {
    try {
        const response = yield call(eventService.deleteEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.deleteEventSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.deleteEventFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.deleteEventFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* addEventReport(request: any): any {
    try {
        const response = yield call(eventService.addEventReport, request.input);
        if (response.success) {
            yield put({ type: eventConstants.addEventReportSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.addEventReportFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.addEventReportFailure, payload: error });
    }
}

function* getEventReports(request: any): any {
    try {
        const response = yield call(eventService.getEventReports, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getEventReportsSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getEventReportsFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getEventReportsFailure, payload: error });
    }
}

function* getUploadedImages(request: any): any {
    try {
        const response = yield call(eventService.getUploadedImages, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getUploadedImagesSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getUploadedImagesFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getUploadedImagesFailure, payload: error });
    }
}

function* updateGallery(request: any): any {
    try {
        const response = yield call(eventService.updateGallery, request.input);
        if (response.success) {
            yield put({ type: eventConstants.updateGallerySuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.updateGalleryFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.updateGalleryFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getApprovedImages(request: any): any {
    try {
        const response = yield call(eventService.getApprovedImages, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getApprovedImagesSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getApprovedImagesFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getApprovedImagesFailure, payload: error });
    }
}

function* getRejectedImages(request: any): any {
    try {
        const response = yield call(eventService.getRejectedImages, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getRejectedImagesSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getRejectedImagesFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getRejectedImagesFailure, payload: error });
    }
}

function* imageUpload(request: any): any {
    try {
        const response = yield call(eventService.imageUpload, request.input);
        if (response.success) {
            yield put({ type: eventConstants.imageUploadSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.imageUploadFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.imageUploadFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* imageApprove(request: any): any {
    try {
        const response = yield call(eventService.imageApproveReject, request.input);
        if (response.success) {
            yield put({ type: eventConstants.imageApproveSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
            yield put({ type: eventConstants.imageProcessStatusRequest, payload: request.input.eventId })
        }
        else {
            yield put({ type: eventConstants.imageApproveFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.imageApproveFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* imageReject(request: any): any {
    try {
        const response = yield call(eventService.imageApproveReject, request.input);
        if (response.success) {
            yield put({ type: eventConstants.imageRejectSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.imageRejectFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.imageRejectFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* imageRemove(request: any): any {
    try {
        const response = yield call(eventService.imageRemove, request.input);
        if (response.success) {
            yield put({ type: eventConstants.imageRemoveSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.imageRemoveFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.imageRemoveFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getByFilterEvent(request: any): any {
    try {
        const response = yield call(eventService.getByFilterEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getByFilterSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getByFilterFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getByFilterFailure, payload: error });
    }
}

function* getAllRSVPresponse(action: any): any {
    try {
        const input = action.payload;
        const response = yield call(eventService.getRSVPresponse, input);
        if (response.success) {
            yield put({ type: eventConstants.getAllRSVPSuccess, payload: response.resultObject });
        } else {
            yield put({ type: eventConstants.getAllRSVPFailure, payload: response.message });
        }
    } catch (error) {
        yield put({ type: eventConstants.getAllRSVPFailure, payload: error });
    }
}

function* getInteraction(request: any): any {
    try {
        const response = yield call(eventService.getInteraction, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getImagesInteractionSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getImagesInteractionFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getImagesInteractionFailure, payload: error });
    }
}

function* createCommentOrReply(request: any): any {
    try {
        const response = yield call(eventService.createCommentOrReply, request.input);
        if (response.success) {
            yield put({ type: eventConstants.createCommentOrReplySuccess, payload: response.resultObject });
            yield put({ type: eventConstants.textProcessStatusRequest, payload: response.resultObject.eventId })
        }
        else {
            yield put({ type: eventConstants.createCommentOrReplyFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.createCommentOrReplyFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* removeCommentOrReply(request: any): any {
    try {
        const response = yield call(eventService.removeCommentOrReply, request.input);
        if (response.success) {
            yield put({ type: eventConstants.removeCommentOrReplySuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.removeCommentOrReplyFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.removeCommentOrReplyFailure, payload: error });
    }
}

function* getAllComments(request: any): any {
    try {
        const response = yield call(eventService.getAllComments, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getCommentListSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getCommentListFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getCommentListFailure, payload: error });
    }
}

function* getAllReplies(request: any): any {
    try {
        const response = yield call(eventService.getAllReplies, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getRepliesListSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getRepliesListFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getRepliesListFailure, payload: error });
    }
}

function* getAllReports(request: any): any {
    try {
        const response = yield call(eventService.getAllReports, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getReportListSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getReportListFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getReportListFailure, payload: error });
    }
}

function* userInteraction(request: any): any {
    try {
        const response = yield call(eventService.userInteraction, request.input);
        if (response.success) {
            yield put({ type: eventConstants.userInteractionSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.userInteractionFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.userInteractionFailure, payload: error });
    }
}

function* addReport(request: any): any {
    try {
        const response = yield call(eventService.addReport, request.input);
        if (response.success) {
            yield put({ type: eventConstants.addReportSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.addReportFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.addReportFailure, payload: error });
    }
}

function* updateCommentOrReply(request: any): any {
    try {
        const response = yield call(eventService.updateCommentOrReply, request.input);
        if (response.success) {
            yield put({ type: eventConstants.updateCommentOrReplySuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
            yield put({ type: eventConstants.textProcessStatusRequest, payload: response.resultObject.eventId })
        }
        else {
            yield put({ type: eventConstants.updateCommentOrReplyFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.updateCommentOrReplyFailure, payload: error });
    }
}

function* textSentimentByMonth(request: any): any {
    try {
        const response = yield call(eventService.textSentimentByMonth, request.input)
        if (response.success) {
            yield put({ type: eventConstants.getTextSentimentByMonthSuccess, payload: response.resultObject });
        } else {
            yield put({ type: eventConstants.getTextSentimentByMonthFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getTextSentimentByMonthFailure, payload: error });
    }
}

function* textSentimentByStats(request: any): any {
    try {
        const response = yield call(eventService.textSentimentByStats, request.input)
        if (response.success) {
            yield put({ type: eventConstants.getTextSentimentByStatsSuccess, payload: response.resultObject });
        } else {
            yield put({ type: eventConstants.getTextSentimentByStatsFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getTextSentimentByStatsFailure, payload: error });
    }
}

function* textSentimentByCategory(request: any): any {
    try {
        const response = yield call(eventService.textSentimentByCategory, request.input)
        if (response.success) {
            yield put({ type: eventConstants.getTextSentimentByCategorySuccess, payload: response.resultObject });
        } else {
            yield put({ type: eventConstants.getTextSentimentByCategoryFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getTextSentimentByCategoryFailure, payload: error });
    }
}

function* imageSentimentByMonth(request: any): any {
    try {
        const response = yield call(eventService.imageSentimentByMonth, request.input)
        if (response.success) {
            yield put({ type: eventConstants.getImageSentimentByMonthSuccess, payload: response.resultObject });
        } else {
            yield put({ type: eventConstants.getImageSentimentByMonthFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getImageSentimentByMonthFailure, payload: error });
    }
}

function* imageSentimentByStats(request: any): any {
    try {
        const response = yield call(eventService.imageSentimentByStats, request.input)
        if (response.success) {
            yield put({ type: eventConstants.getImageSentimentByStatsSuccess, payload: response.resultObject });
        } else {
            yield put({ type: eventConstants.getImageSentimentByStatsFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getImageSentimentByStatsFailure, payload: error });
    }
}

function* imageSentimentByCategory(request: any): any {
    try {
        const response = yield call(eventService.imageSentimentByCategory, request.input)
        if (response.success) {
            yield put({ type: eventConstants.getImageSentimentByCategorySuccess, payload: response.resultObject });
        } else {
            yield put({ type: eventConstants.getImageSentimentByCategoryFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getImageSentimentByCategoryFailure, payload: error });
    }
}

function* generateMeeting(request: any): any {
    try {
        const response = yield call(eventService.generateMeeting, request.input);
        if (response.success) {
            yield put({ type: eventConstants.generateMeetingSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.generateMeetingFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.generateMeetingFailure, payload: error });
    }
}

function* addGalleryReport(request: any): any {
    try {
        const response = yield call(eventService.addGalleryReport, request.input);
        if (response.success) {
            yield put({ type: eventConstants.addGalleryReportSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.addGalleryReportFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.addGalleryReportFailure, payload: error });
    }
}

function* galleryReports(request: any): any {
    try {
        const response = yield call(eventService.galleryReports, request.input);
        if (response.success) {
            yield put({ type: eventConstants.listGalleryReportSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.listGalleryReportFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.listGalleryReportFailure, payload: error });
    }
}

export const eventSaga = {
    getAllEvents, getActiveEvents, getUpComingEvents, getPastEvents,
    createEvent, getEvent, updateEvent, deleteEvent, addEventReport, getEventReports,
    imageUpload, imageApprove, imageReject, imageRemove,
    getUploadedImages, getApprovedImages, getRejectedImages, getByFilterEvent, getAllRSVPresponse,
    getInteraction, createCommentOrReply, removeCommentOrReply, getAllComments, getAllReplies,
    getAllReports, userInteraction, addReport, updateCommentOrReply,
    textSentimentByMonth, textSentimentByStats, textSentimentByCategory,
    imageSentimentByMonth, imageSentimentByStats, imageSentimentByCategory, generateMeeting, addGalleryReport, galleryReports, updateGallery
};