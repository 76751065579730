import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { Empty } from 'antd';

interface sentimentProps {
    chartHeight: number;
}

const TextSentimentByStats: React.FC<sentimentProps> = ({ chartHeight }) => {

    const tsByStats = useSelector((state: any) => state.tsByStats);

    const [sentiment, setSentiment] = useState<any>([]);

    const noData = sentiment?.positiveData?.every((val: number) => val === 0) &&
                           sentiment?.negativeData?.every((val: number) => val === 0) &&
                           sentiment?.neutralData?.every((val: number) => val === 0) &&
                           sentiment?.mixedData?.every((val: number) => val === 0);

    const options: Highcharts.Options = {
        chart: { type: "area", height: `${chartHeight}` },
        title: { text: "Statistics of event sentiments", align: "left", margin: 25 },
        xAxis: { categories: sentiment.dates, },
        yAxis: { title: { text: "Count" }, },
        credits: { enabled: false },
        series: [
            { name: "Positive", type: "area", data: sentiment.positiveData || [], },
            { name: "Negative", type: "area", data: sentiment.negativeData || [], },
            { name: "Neutral", type: "area", data: sentiment.neutralData || [], },
            { name: "Mixed", type: "area", data: sentiment.mixedData || [], },
        ],
    };

    useEffect(() => {
        if (tsByStats.isSuccess) {
            const dates = tsByStats?.tsByStats.map((item: any) => item.period);
            const positiveData = tsByStats?.tsByStats.map((item: any) => item.sentiments.positive);
            const negativeData = tsByStats?.tsByStats.map((item: any) => item.sentiments.negative);
            const neutralData = tsByStats?.tsByStats.map((item: any) => item.sentiments.neutral);
            const mixedData = tsByStats?.tsByStats.map((item: any) => item.sentiments.mixed);

            setSentiment({ dates, positiveData, negativeData, neutralData, mixedData, });
        } else if (tsByStats.isError) {
            setSentiment([])
        }
    }, [tsByStats]);

    return <div className="nodata">
        {noData ? (
            <div>
                <div className='ch-title'>Statistics of Event Sentiments</div>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
        ) : (
            <HighchartsReact highcharts={Highcharts} options={options} />
        )}
    </div>;
};

export default TextSentimentByStats;
