import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Empty } from 'antd';

interface sentimentProps {
  chartHeight: number
}
const TextSentimentByMonth: React.FC<sentimentProps> = ({ chartHeight }) => {
  const highchartColors = Highcharts.getOptions().colors || [];

  const tsByMonth = useSelector((state: any) => state.tsByMonth);

  const [sentiment, setSentiment] = useState([])

  Highcharts.setOptions({
    colors: Highcharts.map(highchartColors, (color: any) => {
      if (typeof color === "string") {
        return {
          radialGradient: { cx: 0.5, cy: 0.3, r: 0.7 },
          stops: [
            [0, color],
            [1, Highcharts.color(color).brighten(-0.3).get("rgb")],
          ],
        };
      }
      return color;
    }),
  });

  const options: Highcharts.Options = {
    chart: {
      plotBackgroundColor: undefined,
      plotBorderWidth: undefined,
      plotShadow: false,
      type: "pie",
      height: `${chartHeight}`,
    },
    title: { text: "Sentiment by month", align: "left" },
    accessibility: { point: { valueSuffix: "%", }, },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          format:
            '<span style="font-size: 1.2em"><b>{point.name}</b></span><br>' +
            '<span style="opacity: 0.6">{point.percentage:.1f}%</span>',
          connectorColor: "rgba(128,128,128,0.5)",
        },
        showInLegend: true,
      },
    },
    credits: { enabled: false, },
    series: [{ name: "Sentiment", type: "pie", data: sentiment, } as Highcharts.SeriesPieOptions,
    ],
  };

  useEffect(() => {
    if (tsByMonth.isSuccess) {
      const order = ["Positive", "Negative", "Neutral", "Mixed"];
      setSentiment(tsByMonth.tsByMonth.map((item: any) => ({
        name: item?.status?.charAt(0)?.toUpperCase() + item.status.slice(1),
        y: item.percentage
      })).sort((a: any, b: any) => order.indexOf(a.name) - order.indexOf(b.name)))
    } else if (tsByMonth.isError) {
      setSentiment([])
    }
  }, [tsByMonth])

  return <div className="nodata">
    {sentiment.length === 0 ? (
      <div>
        <div className='ch-title'>Sentiment by month</div>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    ) : (
      <HighchartsReact highcharts={Highcharts} options={options} />
    )}
  </div>
};

export default TextSentimentByMonth;

