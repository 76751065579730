import { Button } from "antd";

const NoInternetScreen = () => (
    <div style={{ textAlign: 'center', marginTop: '20%', width: '100%' }}>
        <h1>No Internet Connection</h1>
        <p>Please check your network or server and try again.</p>
        <Button type="primary" onClick={() => window.location.reload()}>Retry</Button>
    </div>
);

export default NoInternetScreen;
