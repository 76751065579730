import Axios from 'axios';
import { getAccessToken } from './helper';
import env from './env';
import { error } from 'console';

export const axiosBase = Axios.create({ baseURL: env.apiUrl, });

let setNetworkError: (hasError: boolean) => void;

export const setNetworkErrorHandler = (handler: (hasError: boolean) => void) => {
    setNetworkError = handler;
};

axiosBase.interceptors.request.use(
    async config => {
        const accessToken = getAccessToken();
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosBase.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) {
            if (setNetworkError) setNetworkError(true);
        }
        return Promise.reject(error);
    }
);

export const errorHandler = (error: any) => {
    if (error.response) {
        return error.response.data
    } else if (error.message) {
        return
    } else {
        return { errorMessage: "Something went wrong" }
    }
}

