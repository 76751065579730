import { eventConstants } from "./constants/eventConstant";

const getAllEvents = (request: any) => {
    return { type: eventConstants.getAllEventsRequest, input: request }
}

const getActiveEvents = (request: any) => {
    return { type: eventConstants.getActiveEventsRequest, input: request }
}

const getUpComingEvents = (request: any) => {
    return { type: eventConstants.getUpComingEventsRequest, input: request }
}

const getPastEvents = (request: any) => {
    return { type: eventConstants.getPastEventsRequest, input: request }
}

const createEvent = (request: any) => {
    return { type: eventConstants.createEventRequest, input: request };
};

const getEvent = (request: any) => {
    return { type: eventConstants.getEventRequest, input: request };
};

const updateEvent = (request: any) => {
    return { type: eventConstants.updateEventRequest, input: request };
};

const deleteEvent = (request: any) => {
    return { type: eventConstants.deleteEventRequest, input: request };
};

const addEventReport = (request: any) => {
    return { type: eventConstants.addEventReportRequest, input: request };
};

const getEventReports = (request: any) => {
    return { type: eventConstants.getEventReportsRequest, input: request };
};

const getUploadedImages = (request: any) => {
    return { type: eventConstants.getUploadedImagesRequest, input: request };
};

const getApprovedImages = (request: any) => {
    return { type: eventConstants.getApprovedImagesRequest, input: request };
};

const getRejectedImages = (request: any) => {
    return { type: eventConstants.getRejectedImagesRequest, input: request };
};

const imageUpload = (request: any) => {
    return { type: eventConstants.imageUploadRequest, input: request };
};

const updateGallery = (request: any) => {
    return { type: eventConstants.updateGalleryRequest, input: request };
};

const imageApprove = (request: any) => {
    return { type: eventConstants.imageApproveRequest, input: request };
};

const imageReject = (request: any) => {
    return { type: eventConstants.imageRejectRequest, input: request };
};

const imageRemove = (request: any) => {
    return { type: eventConstants.imageRemoveRequest, input: request };
};

const getByFilter = (request: any) => {
    return { type: eventConstants.getByFilterRequest, input: request };
};

export const getAllRSVP = (params: { status: string, eventId: string, email: string }) => ({
    type: eventConstants.getAllRSVPRequest,
    payload: params
});

const getInteractions = (request: any) => {
    return { type: eventConstants.getImagesInteractionRequest, input: request };
};

const createCommentOrReply = (request: any) => {
    return { type: eventConstants.createCommentOrReplyRequest, input: request };
};

const removeCommentOrReply = (request: any) => {
    return { type: eventConstants.removeCommentOrReplyRequest, input: request };
};

const getALlComments = (request: any) => {
    return { type: eventConstants.getCommentListRequest, input: request };
};

const getALlReplies = (request: any) => {
    return { type: eventConstants.getRepliesListRequest, input: request };
};

const getALlReports = (request: any) => {
    return { type: eventConstants.getReportListRequest, input: request };
};

const userInteraction = (request: any) => {
    return { type: eventConstants.userInteractionRequest, input: request };
};

const addReport = (request: any) => {
    return { type: eventConstants.addReportRequest, input: request };
};

const updateCommentOrReply = (request: any) => {
    return { type: eventConstants.updateCommentOrReplyRequest, input: request };
};

const getTextSentimentByMonth = (request: any) => {
    return { type: eventConstants.getTextSentimentByMonthRequest, input: request };
};

const getTextSentimentByStats = (request: any) => {
    return { type: eventConstants.getTextSentimentByStatsRequest, input: request };
};

const getTextSentimentByCategory = (request: any) => {
    return { type: eventConstants.getTextSentimentByCategoryRequest, input: request };
};

const getImageSentimentByMonth = (request: any) => {
    return { type: eventConstants.getImageSentimentByMonthRequest, input: request };
};

const getImageSentimentByStats = (request: any) => {
    return { type: eventConstants.getImageSentimentByStatsRequest, input: request };
};

const getImageSentimentByCategory = (request: any) => {
    return { type: eventConstants.getImageSentimentByCategoryRequest, input: request };
};

const generateMeetingRequest = (request: any) => {
    return { type: eventConstants.generateMeetingRequest, input: request }
}

const addGalleryReport = (request: any) => {
    return { type: eventConstants.addGallertReportRequest, input: request };
};

const listGalleryReport = (request: any) => {
    return { type: eventConstants.listGallertReportRequest, input: request };
};


export const eventActions = {
    getAllEvents, getActiveEvents, getUpComingEvents, getPastEvents,
    createEvent, getEvent, updateEvent, deleteEvent, addEventReport, getEventReports,
    getUploadedImages, getApprovedImages, getRejectedImages,
    //Gallery
    imageUpload, imageApprove, imageReject, imageRemove, getByFilter, getAllRSVP, getInteractions, listGalleryReport, updateGallery,
    //Comments
    createCommentOrReply, removeCommentOrReply, getALlComments, getALlReplies, getALlReports,
    userInteraction, addReport, updateCommentOrReply,
    //Analytics
    getTextSentimentByMonth, getTextSentimentByStats, getTextSentimentByCategory,
    getImageSentimentByMonth, getImageSentimentByStats, getImageSentimentByCategory,
    //Meeting
    generateMeetingRequest, addGalleryReport
}