import { combineReducers } from 'redux';
import { authenticate, forgotPassword, resendEmail, verifyToken, renewToken, resetPassword, updatePassword } from './authReducer'
import {
    userReducer, clubManager, userCreate, roles, userGetByFilter, getUser, updateUser, deleteUser,
    getProfile, updateProfile, getUserNotification, getNotificationCount, categoryChange

} from './userReducer'
import { getCarouselImages, pastEventList, activeEventList, upComingList, toastMessage, statusReducer, getImages , getUserLocation } from './commonReducer'
import {
    getAllEvents, getActiveEvents, getUpcomingEvents, getPastEvents, CreateEvent, getEvent, updateEvent, deleteEvent, eventReport,eventReports,
    imageUpload, imageApprove, imageReject, imageRemove, uploadedImages, approvedImages, rejectedImages, getByFilterEvent,
    getAllRSVPresponse, getInteractions, commentOrReply, deleteCommentOrReply, comments, replies, reports, userInteraction, addReport,
    textProcess, imageProcess, tsByMonth, tsByStats, tsByCategory, isByMonth, isByStats, isByCategory, generateMeeting, galleryReport, galleryReports , updateGallery
} from './eventReducer'
import {
    clubCreate, clubVerifyOTP, resendOtp, clubReducer, clubGetByFilter, getClub, updateClub, deleteClub,
    clubRegister, getClubByDomain, joinMember, emailResend, resendWhatsApp, verifyPhone, tags, memberApproveReject,memberInvitation, clubReport, clubReportList
} from './clubReducer'

const rootReducer = combineReducers({
    //Auth
    authenticate, forgotPassword, resendEmail, verifyToken, renewToken, resetPassword, updatePassword,

    //Common
    getCarouselImages, pastEventList, activeEventList, upComingList, toastMessage, statusReducer, getImages, getUserLocation,

    //User
    userReducer, clubManager, userCreate, roles, userGetByFilter,
    getUser, updateUser, deleteUser, getProfile, updateProfile,

    //Club
    clubCreate, clubRegister, clubVerifyOTP, resendOtp,
    clubReducer, clubGetByFilter, getClub, updateClub, deleteClub, getClubByDomain,
    joinMember, emailResend, resendWhatsApp, verifyPhone, tags, memberApproveReject,memberInvitation,clubReport,clubReportList,

    //Event
    getAllEvents, getActiveEvents, getUpcomingEvents, getPastEvents, CreateEvent, getEvent, updateEvent, deleteEvent,eventReport,eventReports,
    uploadedImages, approvedImages, rejectedImages, getAllRSVPresponse, imageUpload, imageApprove, imageReject, imageRemove,
    getByFilterEvent, getInteractions, commentOrReply, deleteCommentOrReply, comments, replies, reports, userInteraction,
    addReport, textProcess, imageProcess, tsByMonth, tsByStats, tsByCategory, isByMonth, isByStats, isByCategory, generateMeeting,galleryReport,galleryReports, updateGallery,

    //notification
    getUserNotification, getNotificationCount, categoryChange,
});

export default rootReducer;