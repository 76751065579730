export const common = {
    //Roles
    adminId: "65a7ca1c0d59fb1f949160c1",
    clubMemberId: "674d53d3354fafb6e2246b54",
    admin: "product-admin",
    clubOwner: "club-admin",
    clubManager: "club-manager",
}

export const clubMemberStatus = {
    new: "New",
    approved: "Approved",
    rejected: "Rejected"
}

export enum Role {
    admin = "product-admin",
    clubOwner = "club-admin",
    clubManager = "club-manager",
    Member = "club-member"
}

export enum Base {
    devBase = "azurewebsites",
    localBase = "localhost:3000",
    prodBase = "beefabric.in"
}

export enum Error {
    popUp = "Failed to open popup window"
}

export enum module{
    event= "Event",
    club="Club",
    gallery="Image"
}

export enum clubCategory {
    myClub  = "myClub",
    publicClub = "public"
}