export const commonConstants = {
    showSuccesMessage: "SHOW_SUCCESS_MESSASGE",
    ShowErrorMessage: "SHOW_ERROR_MESSAGE",

    carouselImageRequest: "GET_CAROUSEL_IMAGE_REQUEST",
    carouselImageSuccess: "GET_CAROUSEL_IMAGE_SUCCESS",
    carouselImageFailure: "GET_CAROUSEL_IMAGE_FAILURE",

    pastEventListRequest: "GET_PAST_EVENTLIST_REQUEST",
    pastEventListSuccess: "GET_PAST_EVENTLIST_SUCCESS",
    pastEventListFailure: "GET_PAST_EVENTLIST_FAILURE",

    activeEventListRequest: "GET_ACTIVE_EVENTLIST_REQUEST",
    activeEventListSuccess: "GET_ACTIVE_EVENTLIST_SUCCESS",
    activeEventListFailure: "GET_ACTIVE_EVENTLIST_FAILURE",

    upComingEventListRequest: "GET_UPCOMING_EVENTLIST_REQUEST",
    upComingEventListSuccess: "GET_UPCOMING_EVENTLIST_SUCCESS",
    upComingEventListFailure: "GET_UPCOMING_EVENTLIST_FAILURE",

    getImagesRequest: "GET_IMAGES_REQUEST",
    getImagesSuccess: "GET_IMAGES_SUCCESS",
    getImagesFailure: "GET_IMAGES_FAILURE",

    getUserLocationRequest: "GET_USER_LOCATION_REQUEST",
    getUserLocationSuccess: "GET_USER_LOCATION_SUCCESS",
    getUserLocationFailure: "GET_USER_LOCATION_FAILURE",
}