import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Form, Input } from "antd";
import { MdCardMembership } from "react-icons/md";
import { clubActions } from "../../actions/clubAction";
import GoogleIcon from "../../components/svg/googleLogin";
const protocol = window.location.protocol
import FaceBookIcon from "../../components/svg/facebookLogin";
import Spinner from "../../components/spinner";
import { Base, Role } from "../../actions/constants/common";

const JoinClub: React.FC = () => {

    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const join = useSelector((state: any) => state.joinMember);

    const host = window.location.host.split(".");
    const domainName = host[0]
    const AUTHDATA = localStorage.getItem('AUTHDATA')

    const [domain, setDomain] = useState({
        clubDomain: (protocol === 'http:')
            ? (host.length > 1 ? host[0] : '')
            : (host.length > 2 ? host[0] : '')
    })
    const [member, setMember] = useState({
        email: "",
        firstName: "",
        lastName: "",
        clubDomain: host.length > 1 ? host[0] : '',
        contactNumber: "",
        isSocialSigin: false
    });
    const [isSocialSignIn, setIsSocialSignIn] = useState(false);
    const [ok, setOk] = useState(false);
    const [role, setRole] = useState("");

    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    let firstName = params.get("firstName");
    const handleOnChange = (e: any) => {
        const { name, value } = e.target;
        setMember({ ...member, [name]: value });
    };

    const handleOnBack = () => {
        navigate(-1)
    }

    const handleSubmit = () => {
        dispatch(clubActions.joinMember(member))
    }

    const handleSocialAuthClick = (social: string) => {
        let baseUrl = "";
        if (Base.devBase === host[1]) {
            baseUrl = `${window.location.host}/auth/socialMedia`
        } else {
            if (host.length > (protocol === 'http:' ? 1 : 2)) {
                baseUrl = `${host.slice(1).join(".")}/auth/socialMedia`;
            }
        }
        window.location.replace(protocol === 'http:' ? `http://${baseUrl}?subdomain=${encodeURIComponent(JSON.stringify(domain))}&&social=${social}&&path=${'joinAsMember'}` : `https://${baseUrl}?subdomain=${encodeURIComponent(JSON.stringify(domain))}&&social=${social}&&path=${'joinAsMember'}`);
    };

    if (join && join.isSuccess && join.isJoined) {
        join.isJoined = false;
        setOk(false)
        if (email && firstName) {
            setIsSocialSignIn(true)
        } else {
            setIsSocialSignIn(false)
        }
    }

    const handleHomeOnClick = () => {
        navigate('/')
    }

    useEffect(() => {
        if (email && firstName) {
            dispatch(clubActions.joinMember({ ...member, firstName: firstName, email: email, isSocialSigin: true }))
            setOk(true)
        }
    }, [email])

    useEffect(() => {
        if (AUTHDATA && AUTHDATA) {
            const parsedAuthData = JSON.parse(AUTHDATA);
            const authRole = parsedAuthData.role;
            setRole(authRole)
        } else {
            console.log('No AUTHDATA found in localStorage.');
        }
    }, [AUTHDATA])

    return (
        <div>
            {join.isLoading && ok && <Spinner />}
            {join && !join.isSuccess && !join.isLoading &&
                < div className='create-page'>
                    <div className='page-title' data-testid="pageTitle"> <MdCardMembership />Join as a member</div>
                    <div className="form-wrapper">
                        <Form form={form} onFinish={handleSubmit} initialValues={member} >
                            {join && join.isError && <div className="error-text" data-testid="error-text"> {join.error}</div>}
                            <Form.Item label="First Name" name="firstName" data-testid="firstNameLabel"
                                rules={[{ required: true, message: "First name is required!" }]}>
                                <Input data-testid="firstNameInput" name="firstName" onChange={handleOnChange} />
                            </Form.Item>
                            <Form.Item label="Last Name" name="lastName" data-testid="lastNameLabel">
                                <Input data-testid="lastNameInput" name="lastName" onChange={handleOnChange} />
                            </Form.Item>
                            <Form.Item label="Email" name="email" data-testid="emailLabel"
                                rules={[{ required: true, message: "Email is required!" },
                                { type: "email", message: "The input is not valid E-mail!" }]}>
                                <Input name="email" data-testid="emailInput" onChange={handleOnChange} />
                            </Form.Item>
                            <Form.Item label="WhatsApp Number" name="contactNumber" data-testid="whatsAppLabel"
                                rules={[{ required: false, message: "WhatsApp number is required!" },
                                { message: "The input is not valid number", pattern: new RegExp(/^[0-9]+$/) }]} >
                                <Input name="contactNumber" data-testid="whatsAppInput" onChange={handleOnChange} maxLength={10} minLength={10} />
                            </Form.Item>
                            {Role.Member === role &&
                                <Form.Item label="Club Name">
                                    <Input readOnly value={domainName} name="clubName"/>
                                </Form.Item>
                            }
                            <div className='button-groups'>
                                <Button type="primary" htmlType="button" data-testid="btnCancel" onClick={handleOnBack} > Cancel</Button>
                                <Button type="primary" htmlType="submit" data-testid="btnSubmit"> Join</Button>
                            </div>
                        </Form>
                        <Divider plain style={{ color: "gray" }} data-testid="txtValue">or continue with</Divider>
                        <div className="icon-container">
                            <Button className="social-media-btn" onClick={() => handleSocialAuthClick('google')}>
                                <GoogleIcon data-testid="googleIcon" width={30} hanging={30} />
                            </Button>
                            <Button className="social-media-btn" onClick={() => handleSocialAuthClick('facebook')}>
                                <FaceBookIcon data-testid="facebookIcon" width={30} hanging={30} />
                            </Button>
                        </div>
                    </div>
                </div>}
            {join && join.isSuccess &&
                <div className="member-verified">
                    <h3 data-testid="success-mes">Thank you for joining us!</h3>
                    <div data-testid="success-mes1">We have sent a password update link to your email. Please check it and set up your new password.</div>
                    <Button data-testid="proceed-home" className="proceed-home" type="primary" onClick={handleHomeOnClick} >Home</Button>
                </div>}
        </div >
    )
}

export default JoinClub;