import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { authActions } from '../actions/authAction';
import { useDispatch } from 'react-redux';
import { getCurrentTime, getPromptBeforeIdle, getRenewalTimeBeforeExpiry, getTimeOut } from '../services/helper';

const IdleTimer: React.FC = () => {

  const dispatch = useDispatch();

  const auth = useSelector((state: any) => state.authenticate);
  const renewToken = useSelector((state: any) => state.renewToken);

  const [open, setOpen] = useState<boolean>(false);
  const [logoutCountdown, setLogoutCountdown] = useState<number>(getPromptBeforeIdle() / 1000);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [sessionExpiry, setSessionExpiry] = useState(auth?.authData?.tokenExpery)
  const [timeout, setTimeout] = useState(getTimeOut(auth?.authData?.tokenExpery))
  const [renewalTime, setRenewalTime] = useState<number>(getRenewalTimeBeforeExpiry(auth?.authData?.tokenExpery))
  const [promptBeforeIdle, setPromptBeforeIdle] = useState<number>(getPromptBeforeIdle())

  const onPrompt = () => {
    if (document.visibilityState === 'visible') setOpen(true);
    else reset();
  };

  const onAction = () => {
    const currentTime = getCurrentTime()
    if ((currentTime > renewalTime && currentTime < sessionExpiry) && !open) {
      dispatch(authActions.renewToken({ token: auth?.authData?.token, isMobile: false }))
      reset();
    } else if (currentTime >= sessionExpiry) {
      handleLogout();
    } else if (currentTime >= sessionExpiry && !sessionExpired) {
      setSessionExpired(true);
    }
  };

  const { reset, activate } = useIdleTimer({
    onAction,
    onPrompt,
    timeout,
    promptBeforeIdle,
    debounce: 500,
  });

  const handleStay = () => {
    dispatch(authActions.renewToken({ token: auth?.authData?.token, isMobile: false }))
    setOpen(false);
    activate()
  };

  const handleLogout = () => {
    localStorage.removeItem('AUTHDATA');    
    setOpen(false);
    setSessionExpired(true);
  };

  if (renewToken && renewToken.isSuccess && renewToken.isTokenRenewed) {
    setOpen(false)
    renewToken.isTokenRenewed = false
  }

  useEffect(() => {
    setSessionExpiry(auth?.authData?.tokenExpery)
    setTimeout(getTimeOut(auth?.authData?.tokenExpery))
    setRenewalTime(getRenewalTimeBeforeExpiry(auth?.authData?.tokenExpery))
    setPromptBeforeIdle(getPromptBeforeIdle())
    setLogoutCountdown(getPromptBeforeIdle() / 1000)
  }, [auth]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (open) {
        setLogoutCountdown((prev) => {
          if (prev <= 1) {
            handleLogout();
            return 0;
          }
          return prev - 1;
        });
      }
    }, 1000);

    return () => { clearInterval(interval); };
  }, [open, reset])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (getCurrentTime() >= sessionExpiry) {
          setSessionExpired(true);
        } else {
          reset();
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [sessionExpiry, reset]);

  return (
    <>
      <Modal title="Your session is about to expire!" centered closable={false} maskClosable={false} open={open}
        okText="Stay" cancelText="Logout" onOk={handleStay} onCancel={handleLogout}>
        <div><p>Auto logout in {logoutCountdown} seconds</p></div>
      </Modal>
      <Modal title="Your session has expired." centered closable={false} open={sessionExpired}
        footer={[<Button key="ok" type="primary" onClick={()=> window.location.href = '/login'}>OK</Button>]} width={300}>
        <div>Please log in again.</div>
      </Modal>
    </>
  );
};

export default IdleTimer;



