const env = {
    apiUrl: 'https://beefabricapi.azurewebsites.net/api/v1',
    locationNominationApi : 'https://nominatim.openstreetmap.org/search',
    promptBeforeIdle: .5, //minutes
    renewalTimeBeforeExpiry: 2, //minutes
    googleClientId: '430557436180-oji5sulajik9bc3p6vc4tikf5iulojcc.apps.googleusercontent.com',
    facebookAppId: '1204412374632737',
    zoomSdkKey : 'kW54sM3PRg27vuyESXkFRQ',
    modalUrl : "https://teachablemachine.withgoogle.com/models/jvYMpDMVs/"
}

export default env;