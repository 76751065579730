export const clubConstants = {
    clubCreateRequest: 'CLUB_CREATE_REQUEST',
    clubCreateSuccess: 'CLUB_CREATE_SUCCESS',
    clubCreateFailure: 'CLUB_CREATE_FAILURE',

    clubRegisterRequest: 'CLUB_REGISTER_REQUEST',
    clubRegisterSuccess: 'CLUB_REGISTER_SUCCESS',
    clubRegisterFailure: 'CLUB_REGISTER_FAILURE',

    verifyOtpRequest: "VERIFY_OTP_REQUEST",
    verifyOtpSuccess: "VERIFY_OTP_SUCCESS",
    verifyOtpFailure: "VERIFY_OTP_FAILURE",

    resendOtpRequest: "RESEND_OTP_REQUEST",
    resendOtpSuccess: "RESEND_OTP_SUCCESS",
    resendOtpFailure: "RESEND_OTP_FAILURE",

    getAllClubsRequest: 'GETALL_CLUBS_REQUEST',
    getAllClubsSuccess: 'GETALL_CLUBS_SUCCESS',
    getAllClubsFailure: 'GETALL_CLUBS_FAILURE',

    clubGetByFilterRequest: "CLUB_GETBYFILTER_REQUEST",
    clubGetByFilterSuccess: "CLUB_GETBYFILTER_SUCCESS",
    clubGetByFilterFailure: "CLUB_GETBYFILTER_FAILURE",

    getClubRequest: "GET_CLUB_REQUEST",
    getClubSuccess: "GET_CLUB_SUCCESS",
    getClubFailure: "GET_CLUB_FAILURE",

    updateClubRequest: "UPDATE_CLUB_REQUEST",
    updateClubSuccess: "UPDATE_CLUB_SUCCESS",
    updateClubFailure: "UPDATE_CLUB_FAILURE",

    deleteClubRequest: "DELETE_CLUB_REQUEST",
    deleteClubSuccess: "DELETE_CLUB_SUCCESS",
    deleteClubFailure: "DELETE_CLUB_FAILURE",

    getClubByDomainRequest: "GET_CLUB_BY_DOMAIN_REQUEST",
    getClubByDomainSuccess: "GET_CLUB_BY_DOMAIN_SUCCESS",
    getClubByDomainFailure: "GET_CLUB_BY_DOMAIN_FAILURE",

    addClubReportRequest: "ADD_CLUBREPORT_REQUEST",
    addClubReportSuccess: "ADD_CLUBREPORT_SUCCESS",
    addClubReportFailure: "ADD_CLUBREPORT_FAILURE",

    clubReportListRequest: "CLUBREPORT_LIST_REQUEST",
    ClubReporListSuccess: "CLUBREPORT_LIST_SUCCESS",
    ClubReportListFailure: "CLUBREPORT_LIST_FAILURE",

    getTagsRequest: "GET_TABS_REQUEST",
    getTagsSuccess: "GET_TABS_SUCCESS",
    getTagsFailure: "GET_TABS_FAILURE",

    //Join as a Member
    memberJoiningRequest: "MEMBER_JOINING_REQUEST",
    memberJoiningSuccess: "MEMBER_JOINING_SUCCESS",
    memberJoiningFailure: "MEMBER_JOINING_FAILURE",

    memberEmailResendRequest: "MEMBER_EMAIL_RESEND_REQUEST",
    memberEmailResendSuccess: "MEMBER_EMAIL_RESEND_SUCCESS",
    memberEmailResendFailure: "MEMBER_EMAIL_RESEND_FAILURE",

    memberWhatsAppResendRequest: "MEMBER_WHATSAPP_RESEND_REQUEST",
    memberWhatsAppResendSuccess: "MEMBER_WHATSAPP_RESEND_SUCCESS",
    memberWhatsAppResendFailure: "MEMBER_WHATSAPP_RESEND_FAILURE",

    memberPhoneVerificationRequest: "MEMBER_PHONE_VERIFICATION_REQUEST",
    memberPhoneVerificationSuccess: "MEMBER_PHONE_VERIFICATION_SUCCESS",
    memberPhoneVerificationFailure: "MEMBER_PHONE_VERIFICATION_FAILURE",
    
    approveRejectMemberRequest: "APPROVE_REJECT_MEMBER_REQUEST",
    approveRejectMemberSuccess: "APPROVE_REJECT_MEMBER_SUCCESS",
    approveRejectMemberFailure: "APPROVE_REJECT_MEMBER_FAILURE",
    
    memberInvitationRequest: "MEMBER_INVITATION_REQUEST",
    memberInvitationSuccess: "MEMBER_INVITATION_SUCCESS",
    memberInvitationFailure: "MEMBER_INVITATION_FAILURE",
}


